@import './carbon-components.min.css';

@import-normalize; /* bring in normalize.css styles */

* {
  font-family: 'IBM Plex Sans', sans-serif;
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
}
